import { animate, state, style, transition, trigger } from "@angular/animations";

export const enterAnimation = trigger("enterAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("500ms", style({ opacity: 1 }))
  ]),
  transition(":leave", [
    style({ opacity: 1 }),
    animate("0ms", style({ opacity: 0 }))
  ])
]);

export const addAnimation = trigger("addAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("300ms", style({ opacity: 1 }))
  ]),
  transition(":leave", [
    style({ opacity: 1 }),
    animate("100ms", style({ opacity: 0 }))
  ])
]);

export const rotateAnimation = trigger("rotate", [
  state("true",
    style({ transform: "rotate(180deg)" })
  ),
  state("false",
    style({ transform: "rotate(0deg)" })
  ),
  transition("false <=> true", animate("100ms"))
]);